import React, { Component } from 'react';
import style from './Blog.module.css';


class Blog extends Component {
  render() {
    return (
      <div className={style.blog}>
        I'm the Blog
      </div>
    );
  }
}

export default Blog;
