import React, { Component } from 'react';
import style from './DC.module.css';


class DC extends Component {
  render() {
    return (
      <div className={style.dc}>
        Daily Coder
      </div>
    );
  }
}

export default DC;
